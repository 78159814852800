import {
  Box,
  makeStyles,
  Grid,
  IconButton,
  Typography,
  Drawer,
  Menu,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Sidebar from "./Sidebar";
import { useHistory, useLocation } from "react-router-dom";
import { getApiHandler, postApiHandlerWithToken } from "../../config/service";
import toast from "react-hot-toast";
const useStyles = makeStyles((theme) => ({
  headerbox: {
    backgroundColor: "rgb(255,255,255)",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "10px 20px 10px 8px",

    boxShadow: "0 0 10px 0 #D9D9D9",
    "& .drawer": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    "& body2": {
      fontWeight: 400,
      fontSize: "16px !important",
      color: "#344054",
      textAlign: "center !important",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      maxWidth: "413px !important",
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 0px",
    },
    "& h2": {
      width: "fit-content",
    },
    "& h4": {
      fontWeight: 600,
      fontSize: "28px !important",
      color: "#0C111D",
      lineHeight: "36px",
    },
    "& h5": {
      fontWeight: 400,
      fontSize: "16px !important",
      color: "#0C111D !important",
      textAlign: "center",
      lineHeight: "22px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px !important",
      },
    },

    "& hr": {
      margin: "10px 0",
    },
    [theme.breakpoints.down("xs")]: {
      "& button": {
        width: "90px !important",
      },
    },
  },
  logos: {
    marginRight: "auto",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "auto", // Adjust as necessary
  },
  iconText: {
    marginLeft: "0px", // Adjust as necessary
    color: "#0C111D",
    fontSize: "16px",
  },
  list: {
    width: 250,
  },
}));
const initialNotifications = [
  {
    message:
      "Lorem ipsum dolor, sit amet consectetur elit. Magnam, reprehenderit. Dolorum, odit?",
    date: "Friday 12 November at 10:30 AM",
    id: 1,
  },
  {
    message:
      "Lorem ipsum dolor, sit amet consectetur elit. Magnam, reprehenderit. Dolorum, odit?",
    date: "Friday 12 November at 10:30 AM",
    id: 2,
  },
  {
    message:
      "Lorem ipsum dolor, sit amet consectetur elit. Magnam, reprehenderit. Dolorum, odit?",
    date: "Friday 12 November at 10:30 AM",
    id: 3,
  },
];
const Notification = ({ notification, handleRemove }) => (
  <Box
    sx={{
      fontSize: "14px",
      fontWeight: "400",
      padding: "8px",
      color: "#202123",

      background: "#F8F8F8",
      marginTop: "10px",
      borderRadius: "10px",
    }}
  >
    <Box sx={{ display: "flex" }}>
      <p>{notification.message}</p>
      <Box sx={{ cursor: "pointer", marginTop: "12px" }}>
        <img
          src="/images/cross.svg"
          onClick={() => handleRemove(notification.id)}
        />
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: "12px",
        marginTop: "-20px",
        color: "#9e9c9c",
      }}
    >
      <p>{notification.date}</p>
      <p>60 mins ago</p>
    </Box>
  </Box>
);
const Header = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  const [anchorNoti, setAnchorNoti] = useState(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isSubscribeOpen, setIsSubscripeOpen] = useState(false);
  const openNoti = Boolean(anchorNoti);
  const handleNotiClose = () => {
    setAnchorNoti(null);
  };
  const handleRemove = (id) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };
  const [plans, setPlans] = useState([]);
  const [notifications, setNotifications] = useState(initialNotifications);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleNotiClick = (event) => {
    setAnchorNoti(event.currentTarget);
  };
  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };
  const handleStartFreeTrialClick = () => {
    setIsSubscripeOpen(true);
  };

  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };
  const handleCloseSubscribeModal = () => {
    setIsSubscripeOpen(false);
  };
  const drawerList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Sidebar onLogoutClick={handleLogoutClick} />
    </div>
  );

  const handleSubscribe = async () => {
    try {
      const res = await postApiHandlerWithToken("startFreeTrialPlan");
      if (res.responseCode == 200) {
        toast.success("Trial plan activated successfully.");
        window.sessionStorage.setItem(
          "isTrialPlanConsumed",
          res?.result?.userDetails?.isTrialPlanConsumed
        );
        const subscriptionDetails =
          res?.result?.userDetails?.subscriptionDetails;
        if (subscriptionDetails) {
          window.sessionStorage.setItem(
            "subscriptionDetails",
            JSON.stringify(subscriptionDetails)
          );
        }
      } else {
        toast.error("Unable to process your request. Please try again.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubscripeOpen(false);
    }
  };

  const getPlans = async () => {
    try {
      const isTrialPlan = true;
      const res = await getApiHandler("getAllSubscriptionPlan", {
        isTrialPlan,
      });
      if (res.responseCode == 200) {
        setPlans(res?.result?.totalSubscription);
      } else {
        toast.error(
          "Unable get the plans at the moment. Please try again after sometime."
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const isTrialPlanConsumed = JSON.parse(
    sessionStorage.getItem("isTrialPlanConsumed")
  );
  return (
    <Box className={classes.headerbox}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item className="drawer">
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item className={classes.logos}>
          <img
            onClick={() => history.push("/overview")}
            style={{ cursor: "pointer" }}
            src="/images/orangeLogo.svg"
            alt="Logo"
          />
        </Grid>
        <Grid item className={classes.icons}>
          {!isTrialPlanConsumed && (
            <Box style={{ cursor: "pointer", marginRight: "20px" }}>
              <Button
                onClick={handleStartFreeTrialClick}
                style={{ cursor: "pointer" }}
                variant="outlined"
                color="primary"
              >
                Start {plans[0]?.duration} days Free Trial
              </Button>
            </Box>
          )}
          <IconButton onClick={() => history.push("/application")} >
            <img
              src="/images/apps.svg"
              alt="Overview Icon"
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
          <Typography variant="body1" className={classes.iconText}>
            Apps
          </Typography>
          {/* <IconButton style={{ marginLeft: "20px" }} onClick={handleNotiClick}>
            <img src="/images/Icon.svg" alt="Overview Icon" />
          </IconButton> */}
          <IconButton onClick={() => history.push("/profile")} style={{marginLeft : "12px"}} >
            <img src="/images/Profile.svg" alt="Overview Icon" />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorNoti}
        id="account-menu"
        open={openNoti}
        onClose={handleNotiClose}
        // onClick={handleNotiClose}
        style={{
          marginTop: "50px",
          padding: "10px",
        }}
        PaperProps={{
          elevation: 5,
          style: {
            overflow: "hidden",
            mt: 1.5,
            width: "25rem",
            // [theme.breakpoints.down("sm")]: {
            //   width: "22.5rem",
            // },
            "@media(max-width: 380px)": {
              width: "19.5rem",
            },
            "@media(max-width: 340px)": {
              width: "16.1rem",
            },

            padding: " 1rem 1rem !important",
            height: "auto",
            borderRadius: "10px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
              padding: " 0.5rem",
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "relative",
              top: 0,
              bgcolor: "background.paper",
              zIndex: 0,
            },
            "& MuiMenu-list": {
              padding: "0px !important",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontSize: "14px" }}>
            <h2 sx={{}}>Notifications</h2>
          </Box>
          <Box>
            <Button
              onClick={() => setNotifications(null)}
              sx={{ color: "#523168", textTransform: "capitalize" }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
        <Divider />
        {notifications
          ? notifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                handleRemove={handleRemove}
              />
            ))
          : ""}
        <Box sx={{ marginTop: "0.7rem" }}>
          <Divider />
          <Button
            sx={{
              color: "#523168",
              textTransform: "none",
              fontSize: "15px",

              fontWeight: "500",
              padding: "4% 0",
              "&:first-letter": {
                textTransform: "uppercase",
              },
            }}
            // onClick={() => {
            //   navigate("/program");
            // }}
          >
            View all notifications
          </Button>
        </Box>
      </Menu>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
      {isSubscribeOpen && (
        <Dialog
          open={isSubscribeOpen}
          onClose={() => handleCloseSubscribeModal()}
          className={classes.dialog}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogContent>
            <Box className={classes.mainBox}>
              <img src="/images/logOutPopup.svg" alt="gps" />
              <Typography variant="h4" style={{ marginTop: "20px" }}>
                Start Free Trial
              </Typography>
              <Typography variant="h5" style={{ marginTop: "20px" }}>
                Enjoy full access to our premium features for {plans[0]?.duration} days—no credit
                card required!
              </Typography>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    style={{
                      marginTop: "25px",
                      background: "#E1E1E1",
                      color: "#0C111D",
                      width: "150px",
                    }}
                    onClick={() => setIsSubscripeOpen(false)}
                  >
                   Maybe Later
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    style={{ marginTop: "25px", width: "150px" }}
                    onClick={handleSubscribe}
                  >
                    Start Free Trial
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default Header;
