// const url = "http://172.16.6.83:2138/api/v1";    //Izhar bhai
// const url = "http://172.16.6.39:2138/api/v1";
// const url = "http://localhost:2138/api/v1";
const url = "https://node-chatsdk.mobiloitte.io/api/v1";
// const url="https://node.waffrly.com/api/v1";
const ApiConfig = {
    url,
    signup: `${url}/user/signUp`,
    login: `${url}/user/userLogin`,
    otp:`${url}/user/resendOTP`,
    verifyOTP:`${url}/user/verifyOTP`,
    resetPassword:`${url}/user/resetPassword`,
    createApp:`${url}/user/createApp`,
    listAllApps:`${url}/user/listAllApps`,
    listAllChatUsers:`${url}/user/listAllChatUsers`,
    listAllChatGroups:`${url}/user/listAllChatGroups`,
    addAppKey:`${url}/user/addAppKey`,
    getAppDetails:`${url}/user/getAppDetails`,
    updateAppKeyDetails:`${url}/user/updateAppKeyDetails`,
    listPreviousChatMessages:`${url}/user/listPreviousChatMessages`,
    listChatCallsHistory:`${url}/user/listChatCallsHistory`,
    successPayment: `${url}/transaction/successPayment`,
    getAllTransactions: `${url}/transaction/getAllTransactions`,
 createPaymentIntent: `${url}/transaction/createPaymentIntent`, 
    getUserDetails:`${url}/user/getUserDetails`,
    changePassword:`${url}/user/changePassword`,
    getOneStaticContent:`${url}/staticContent/getOneStaticContent`,
    createContactUs:`${url}/contactUs/createContactUs`,
    updateChatUserStatus:`${url}/user/updateChatUserStatus`,
    listAllSessionHistory:`${url}/user/listAllSessionHistory`,
    listAllTeamMembers:`${url}/user/listAllTeamMembers`,
    updateUserDetails:`${url}/user/updateUserDetails`,
    updateAppDetails:`${url}/user/updateAppDetails`,
    appOverviewDetails:`${url}/user/appOverviewDetails`,
    checkout:`${url}/subscription/checkout`,
    getAllSubscriptionPlan:`${url}/subscription/getAllSubscriptionPlan`,
    getActiveSubscriptionPlan:`${url}/subscription/viewActiveSubscriptionPlan`,
    startFreeTrialPlan:`${url}/transaction/startFreeTrialPlan`,


}
export default ApiConfig;
